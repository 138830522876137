.loader_wrapper {
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 1035;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  .timer_wrapper {
    border: 1px solid rgba(64, 64, 64, 0.6);
    background: #0d0d1a;
    backdrop-filter: blur(2px);
    padding: 8px 16px;
    box-sizing: border-box;
    border-radius: 6px;
  }

  &.dark {
    background: rgba(0, 0, 0, 1);
  }

  .lds-dual-ring {
    color: #3602A3
  }
  .lds-dual-ring,
  .lds-dual-ring:after {
    box-sizing: border-box;
  }
  .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6.4px solid currentColor;
    border-color: currentColor transparent currentColor transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
@keyframes mbar {
  0% {
    left: -35%;
    right: 100%;
  }

  60% {
    left: 100%;
    right: -90%;
  }

  100% {
    left: 100%;
    right: -35%;
  }
}

