.tasks_page {
  .frame_content {
    padding-top: 22px;
  }

  &_title {
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    font-family: $saira-600;
    margin: 0 0 20px;
  }

  &_description {
    font-size: 16px;
    text-align: center;
    color: $white_50;
    margin: 0 0 20px;
    line-height: 18px;
    font-family: $saira-300;
  }

  .types {
    display: flex;
    border-bottom: 1px solid rgba(64, 64, 64, 0.6);
    width: 100%;
    margin: 0 0 20px;


    &_type {
      padding: 8px;
      display: flex;
      width: 50%;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      color: $white_50;
      font-family: $saira-500;
      border-left: 1px solid;
      border-top: 1px solid;
      border-right: 1px solid;
      border-color: transparent;

      &.active {
        border-color: rgba(64, 64, 64, 0.6);
        color: #fff;
        background: rgba(255, 255, 255, 0.04);
        backdrop-filter: blur(2px);
      }
    }

    &_community {
      border-top-left-radius: 16px;
    }

    &_daily {
      border-top-right-radius: 16px;
    }
  }

  .tasks_list {
    width: 100%;
    overflow: scroll;
    position: relative;
    height: calc(100% - 149px);

    .no_tasks {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    .community {

      &_items {
        .community_item {
          padding: 16px 0;
          box-sizing: border-box;
          border-bottom: 1px solid rgba(64, 64, 64, 0.6);
          display: flex;
          align-items: center;

          .item_logo {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background-color: rgba(217, 217, 217, 0.1);
            padding: 4px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 12px;
            font-size: 16px;
            font-family: $saira-700;
            letter-spacing: 1.5px;

            img {
              display: block;
              width: 100%;
              height: auto;
              max-width: 12px;
            }
          }

          .item_description {
            h2 {
              font-family: $saira-300;
              margin: 0 0 4px;
              font-size: 14px;
              line-height: 16px;
              color: $white_80;
            }

            p {
              margin: 0;
              font-size: 12px;
              line-height: 14px;
              font-family: $saira-700;
              color: #fff;
            }
          }

          .item_actions {
            display: flex;
            margin: 0 0 0 auto;

            &_start {
              margin-right: 4px;

              button {
                position: relative;
                border-radius: 4px;
                font-family: $saira-400;
                background-image: linear-gradient(to right, #FFCE83 16%, #FE8C81 51%);
                background-size: 200% auto;
                border: 1px solid #2c2c31;
                @media all and (min-width: 380px){
                  padding: 4px 16px;
                }

                .proceed_layer {
                  display: none;
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  justify-content: center;
                  align-items: center;
                  top: 0;
                  left: 0;
                  background: rgba(0, 0, 0, 0.5);
                }

                &.proceed {
                  .proceed_layer {
                    display: flex;
                  }
                }
              }
            }

            &_verify {
              border: 1px solid rgba(64, 64, 64, 0.6);
              color: #fff;
              border-radius: 4px;
              font-family: $saira-400;
              background: rgba(255, 255, 255, 0.04);
              backdrop-filter: blur(2px);
              cursor: pointer;
              @media all and (min-width: 380px){
                padding: 4px 16px;
              }

              &:disabled {
                opacity: 0.5;
                cursor: not-allowed;
              }
            }
          }
        }
      }
    }

    &.daily {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -6px;
      align-items: flex-start;
      align-content: center;
    }

    .daily {

      &_item {
        border-radius: 12px;
        padding: 6px;
        box-sizing: border-box;
        width: 25%;

        &.checked {
          .daily_item_wrapper {

            background: rgb(23,198,91);
            background: linear-gradient(180deg, rgba(23,198,91,1) 0%, rgba(0,121,41,1) 100%);
            //background-image: linear-gradient(to right, #FFCE83 16%, #FE8C81 51%);
          }

          .daily_item_day {
            color: #fff;
          }

          .daily_item_reward {
            color: #fff;
          }
        }

        &.current {
          .daily_item_wrapper {
            border: 1px solid #17c65b;
          }
        }

        &_wrapper {
          border: 1px solid rgba(64, 64, 64, 0.6);

          background: rgba(255, 255, 255, 0.04);
          padding: 10px 6px;
          border-radius: 16px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
        }

        &_day {
          display: block;
          font-family: $saira-700;
          margin: 0 0 5px;
          line-height: 1;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.5);
        }

        &_icon {
          margin: 0 0 5px;

          img {
            display: block;
            width: 30px;
            height: auto;
          }
        }

        &_reward {
          font-size: 12px;
          font-family: $saira-500;
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }
}