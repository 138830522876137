.rotated_device {
  position: fixed;
  background: $main_violet;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  opacity: 0;
  z-index: -1;

  &.mobile {
    opacity: 1;
    z-index: 101;
  }

  .qr_image {
    width: 100%;
    max-width: 200px;
    margin: 0 auto 20px;
    display: block;
    border-radius: 10px;
  }

  @media screen and (orientation: landscape) {
    display: flex;
    opacity: 1;
    z-index: 100;
  }

  h1 {
    font-family: $saira-600;
    font-size: 28px;
    color: #fbb383;
    margin: 0 0 20px;
    text-align: center;
    max-width: 300px;
    letter-spacing: 1px;
    line-height: 30px;
  }
  p {
    font-family: $saira-400;
    margin: 0;
    font-size: 16px;
    color: $white_80;
    text-align: center;
    max-width: 300px;
  }
}