
.main_page {
  height: 100%;
  display: flex;
  flex-direction: column;

  .user {
    display: flex;
    margin-bottom: 16px;
    align-items: center;

    &_ini {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: rgba(217, 217, 217, 0.1);
      padding: 2px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 12px;
      font-size: 16px;
      font-family: $saira-700;
      letter-spacing: 1.5px;

      img {
        display: block;
        width: 100%;
        border-radius: 50%;
        height: auto;

      }
    }

    &_name {
      font-family: $saira-500;
      color: $white_80;
      font-size: 14px;
    }

  }

  .dashboard {
    margin: 0 -16px;
    background: $main_violet;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    padding: 32px 16px 0;
    //@media all and (max-height: 670px) {
    //  height: auto;
    //}

    &:after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -2;
      background: linear-gradient(179deg, rgba(255, 206, 131, 0.60) 0.44%, rgba(254, 140, 129, 0.60) 12.34%, rgba(54, 2, 163, 0.60) 28.45%);
      filter: blur(44.349998474121094px);
    }

    &:before {
      position: absolute;
      content: '';
      top: -3px;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      border-top-left-radius: 32px;
      border-top-right-radius: 32px;
      background: linear-gradient(88deg, #FFCE83 5.04%, #FE8C81 32.65%, #3602A3 94.51%);
    }

    .top_data {
      display: flex;
      justify-content: space-between;
      margin: 0 -8px 32px;

      &_item {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 0 8px;
        box-sizing: border-box;

        &:nth-child(1) {
          .top_data_item_value {
            &_icon {
              width: 32px;
            }
          }
        }

        &:nth-child(2) {
          align-items: center;
        }

        &:nth-child(3) {
          align-items: flex-end;
        }

        &_title {
          font-size: 12px;
          font-family: $saira-300;
          line-height: normal;
          color: $white-50;
          margin: 0 0 8px;
        }

        &_value {
          display: flex;
          align-items: center;

          &_icon {
            display: block;
            width: 24px;
            height: auto;
            margin-right: 8px;
          }

          &_value {
            font-family: $saira-500;
            font-size: 16px;
            white-space: nowrap;
          }
        }
      }
    }

    .total_data {
      display: flex;
      align-items: center;
      justify-content: center;

      &_icon {
        margin-right: 16px;
        display: block;
        width: 44px;
        height: auto;
      }

      &_value {
        font-size: 28px;
        font-family: $saira-500;
      }
    }
  }

  .claim_data {
    width: 90%;
    aspect-ratio: 1 / 1;
    margin: auto;
    position: relative;
    border-radius: 50%;
    @media all and (max-width: 380px) {
      max-width: 270px;
    }

    &.click {
      .claim_data_img {
        animation: pop 0.3s linear 1;
      }
    }

    &_img {
      position: absolute;
      width: 44%;
      height: auto;
      display: block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(1);
      z-index: 2;
      //@media all and (min-width: 380px) {
      //  width: 140px;
      //}
    }

    .chart_wrapper {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 0;
      &:before {
        position: absolute;
        width: 100%;
        height: 100%;
        content: '';
        background: linear-gradient(180deg, rgba(251, 179, 131, 1) 0%, rgba(200, 90, 161, 1) 50%, rgba(144, 61, 191, 1) 100%);
        filter: blur(80px);
        opacity: 0.3;
        z-index: -1;
      }
      svg {
        z-index: 1;
      }
    }

  }

  .boost_data {
    padding: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    margin: auto 0 0;

    &_item {
      display: flex;
      align-items: center;

      img {
        display: block;
        margin-right: 8px;
        height: auto;
      }

      &:nth-child(1) {
        img {
          width: 25px;
        }
      }

      &:nth-child(2) {
        img {
          width: 40px;
        }
      }

      &_value {
        font-family: $saira-500;


      }
    }
  }

  .pot_data {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_icon {
      position: relative;

      &.time {
        width: 42px;
        display: flex;
        justify-content: center;

        &:after {
          left: 50%;
          transform: translateX(-50%);
          text-align: center;
          width: 45px;
        }
      }

      &.pot {
        width: 42px;
        display: flex;
        justify-content: center;

        &:after {
          left: 0;
        }
      }

      &:after {
        content: attr(data-attr);
        position: absolute;
        top: -20px;
        font-size: 10px;
        font-family: $saira-300;
        line-height: normal;
        color: $white-50;

      }

      img {
        display: block;
        height: auto;
        width: 20px;
      }

    }

    &_progress {
      display: flex;
      align-items: center;

      &_current {
        font-size: 14px;
        margin-right: 6px;
        width: 54px;
        text-align: end;

        &.full_bucket {
          & > span {
            display: inline-block;
            color: $red_color;
            transform: scale(1);
            animation: full 1s linear infinite;
          }

        }
      }

      &_progress {
        height: 7px;
        margin-top: 2px;
        width: 100px;
        position: relative;

        &:after {
          position: absolute;
          width: 100%;
          content: '';
          height: 1px;
          top: 3px;
          left: 0;
          background: $white_50;
        }

        &:before {
          position: absolute;
          width: var(--pct);
          content: '';
          transition: width 0.3s linear;
          height: 7px;
          top: 0;
          left: 0;
          z-index: 1;
          background: linear-gradient(270deg, #FBB383 -5.73%, #C85AA1 38.68%, #903DBF 100%);
        }

      }

      &_full {
        font-size: 14px;
        margin-left: 6px;
        width: 54px;
      }
    }
  }

  @keyframes full {
    0% {
      transform: scale(1);
    }
    25% {
      transform: scale(0.95);
    }
    50% {
      transform: scale(1);
    }
    75% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes pop {
    0% {
      transform: translate(-50%, -50%) scale(1);
    }
    25% {
      transform: translate(-50%, -50%) scale(0.9);
    }
    50% {
      transform: translate(-50%, -50%) scale(1);
    }
    75% {
      transform: translate(-50%, -50%) scale(1.1);
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
    }
  }


}

.level_up_popup {
  position: fixed;
  z-index: 5;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: $main_violet;
  opacity: 1;
  padding: 16px 16px 80px;

  p {
    font-size: 16px;
    color: $white_80;
    text-align: center;
    max-width: 300px;
    margin: 0 auto 0;
    font-family: $saira-400;


    .points {
      font-family: $saira-700;
      color: #fbb383;
    }
  }

  &.hide {
    transition: opacity 2s ease-in-out;
    opacity: 0;
  }

  img {
    display: block;
    width: 200px;
    animation: lvl 0.5s linear 1;
  }
}

@keyframes lvl {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
