.nav {
  padding: 16px;
  list-style: none;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: $main_violet;
  z-index: 4;

  &_wrapper {
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 4px;
    list-style: none;
    border: 1px solid rgba(64, 64, 64, 0.6);
    background: rgba(255, 255, 255, 0.04);
    backdrop-filter: blur(2px);
    position: relative;
    overflow: hidden;
  }

  &_item {

    box-sizing: border-box;
    width: 25%;
    a {
      pointer-events: unset;
      border-radius: 12px;
      padding: 12px;
      color: $white_50;
      text-align: center;
      font-size: 14px;
      line-height: 1;
      text-decoration: none;
      display: flex;
      font-family: $saira-400;
      justify-content: center;
      align-items: center;

      &.active {
        color: #fff;
        position: relative;
        background: rgba(64, 64, 64, 0.47);
      }
    }


  }
}
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: none;
}
