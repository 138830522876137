.frame {
  height: calc(100%);

  &_header {
    height: 40px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    font-family: $saira-400;
    line-height: 1;
  }

  &_content {
    margin: 0 -16px;
    background: $main_violet;
    height: calc(100% - 78px);
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    padding: 16px 16px 0;
    @media all and (max-height: 670px) {
      //height: auto;
    }

    &:after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -2;
      background: linear-gradient(179deg, rgba(255, 206, 131, 0.60) 0.44%, rgba(254, 140, 129, 0.60) 12.34%, rgba(54, 2, 163, 0.60) 28.45%);
      filter: blur(44.349998474121094px);
    }

    &:before {
      position: absolute;
      content: '';
      top: -3px;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      border-top-left-radius: 32px;
      border-top-right-radius: 32px;
      background: linear-gradient(88deg, #FFCE83 5.04%, #FE8C81 32.65%, #3602A3 94.51%);
    }
  }
}

