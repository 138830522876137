$grey_color: #a6a6a6;
$white_80: rgba(255, 255, 255, 0.80);
$white_50: rgba(255, 255, 255, 0.50);
$main_violet: #030311;
$red_color: #bc2c0d;
$theme-color: linear-gradient(88deg, #FFCE83 5.04%, #FE8C81 32.65%, #3602A3 94.51%);

//Fonts
$saira-300: Saira-300, san-serif;
$saira-400: Saira-400, san-serif;
$saira-500: Saira-500, san-serif;
$saira-600: Saira-600, san-serif;
$saira-700: Saira-700, san-serif;
