.penalty_shot {
  position: relative;

  .frame_content {
    padding-top: 22px;
    min-height: calc(100% - 78px);
    height: auto;
  }

  &_header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 22px;
    }
  }

  .goal_zone {
    position: relative;
    min-height: 450px;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    overflow: hidden;
    margin-bottom: 16px;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    width: 343px;

    .board {
      position: absolute;
      display: flex;
      flex-direction: column;
      bottom: 16px;
      &_item {
        display: flex;
        align-items: center;
        color: $white_80;
        font-family: $saira-400;
        &.current {
          color: #fbb383;
          font-family: $saira-700;
          padding: 4px 0;
          box-sizing: border-box;
        }
        &.pass {
          color: #17c65b;
        }

        &_num {
          font-size: 12px;
          font-family: $saira-700;
          margin-right: 3px;
        }
        &_value {
          font-size: 12px;
          margin-right: 6px;
          & > span {
            font-family: $saira-700;
          }
        }

        &_ball {
          width: 12px;
          height: 12px;
          display: block;
        }

        &_current {
          width: 20px;
          transform: translateX(0);
          animation: fade_right 1s ease infinite;
        }
      }
    }

    .targets {
      position: absolute;
      height: 35px;
      width: 100%;
      top: 10%;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      z-index: 3;

      &_wrapper {
        position: relative;
        width: 100%;
        height: 100%;
      }

      &_target {
        position: absolute;
        height: 25px;
        width: 25px;
        border-radius: 50%;

        img {
          display: block;
          width: 100%;
        }

        &:nth-child(1) {
          left: 13%;
          bottom: -160%;
        }

        &:nth-child(2) {
          left: 50%;
          top: -13%;
          transform: translateX(-50%);
        }

        &:nth-child(3) {
          right: 13%;
          bottom: -160%;
        }

        &.selected {
          background: #fff;
        }

        &:hover {
          background: #fff;
        }
      }
    }

    .goal_image {
      display: block;
      width: 100%;
      height: auto;
      object-fit: cover;
    }

    .ball_image {
      position: absolute;
      width: 30px;
      height: auto;
      left: 50%;
      bottom: 26%;
      transform: translateX(-50%);
      z-index: 3;

      &.left {
        animation: left 0.3s linear forwards, spin 0.3s linear forwards;
      }

      &.center {
        animation: center 0.3s linear forwards, spin 0.3s linear forwards;
      }

      &.right {
        animation: right 0.3s linear forwards, spin 0.3s linear forwards;
      }
    }
  }

  .keeper_image {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 59%;
    width: 75%;
  }

  .is_goal {
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 32px;
    font-weight: 600;
    transform: translate(-50%, -50%);
  }

  @keyframes left {
    0% {
      left: 50%;
      bottom: 22%;
    }
    100% {
      left: 18%;
      bottom: 66%;
      width: 20px;

    }
  }
  @keyframes center {
    0% {
      bottom: 22%;
    }
    100% {
      bottom: 82%;
      left: 56%;
      width: 20px;

    }
  }
  @keyframes right {
    0% {
      left: 50%;
      bottom: 22%;
    }
    100% {
      left: 82%;
      bottom: 66%;
      width: 20px;
    }
  }
  @keyframes spin {
    100% {
      transform: translateX(-50%) rotate(720deg);
    }
  }
}

@keyframes fade_right {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0);
  }
}


.kick_button {
  padding: 0.6em 2em;
  margin-bottom: 16px;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  &:before {
    content: "";
    background: linear-gradient(
                    45deg,
                    #FFCE83,
                    #FE8C81,
                    #3602A3,
    );
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing-button-85 20s linear infinite;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }

  &:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #222;
    left: 0;
    top: 0;
    border-radius: 10px;
  }

  &.not_time {
    &:before {
      background: none;
    }
  }
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

