.boost_list_wrapper {

  .frame_content {
    height: calc(100% - 72px);
  }

  .boost_list {
    position: relative;
    //background: $main_violet;
    height: 100%;
    //display: flex;
    //flex-direction: column;
    //position: relative;
    //border-top-left-radius: 32px;
    //border-top-right-radius: 32px;
    padding: 32px 16px 0;
    max-width: 430px;
    width: 100%;
    //margin: 0 auto;
    //box-sizing: border-box;
    //
    //
    //&:before {
    //  position: absolute;
    //  content: '';
    //  top: -3px;
    //  left: 0;
    //  width: 100%;
    //  height: 100%;
    //  z-index: -1;
    //  border-top-left-radius: 32px;
    //  border-top-right-radius: 32px;
    //  background: linear-gradient(88deg, #FFCE83 5.04%, #FE8C81 32.65%, #3602A3 94.51%);
    //}
    //
    //.close_boosts {
    //  position: absolute;
    //  right: 22px;
    //  top: 22px;
    //  display: block;
    //  width: 20px;
    //  height: auto;
    //}

    .total_data {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 32px;

      &_icon {
        margin-right: 16px;
        display: block;
        width: 58px;
        height: auto;
      }

      &_value {
        font-size: 32px;
        font-family: $saira-500;
      }
    }

    &_title {
      font-size: 16px;
      text-align: center;
      color: $white_50;
      margin: 0 0 26px;
      line-height: 18px;
      font-family: $saira-300;
    }

    .boost_item {
      box-sizing: border-box;
      width: 100%;
      margin-bottom: 16px;
      overflow: hidden;
      height: 105px;
      position: relative;
      padding-bottom: 16px;
      border-bottom: 1px solid rgba(64, 64, 64, 0.6);
      display: flex;

      &_poster {
        width: 30%;
        border-radius: 6px;
        overflow: hidden;
        border: 1px solid rgba(64, 64, 64, 0.6);
        margin-right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        &.available {
          border-color: #1ee434b8;
        }
        img {
          display: block;
          width: 50px;
          height: auto;
          object-fit: contain;
          z-index: 0;
        }
      }
      &_description {
        width: 70%;
        display: flex;
        flex-direction: column;
        .title_wrapper {
          margin: 0 0 12px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .game_lock {
            display: flex;
            img {
              width: 14px;
              margin-right: 4px;
            }
            p {
              margin: 0;
              font-family: $saira-700;
              font-size: 12px;
            }
          }
        }
        &_title {
          font-size: 16px;
          margin: 0 0 6px;
          line-height: 22px;
          box-sizing: border-box;
          position: relative;
          z-index: 1;
          font-family: $saira-500;
        }

        &_text {
          margin: 0 0 10px;
          color: $white_50;
          font-size: 12px;
          box-sizing: border-box;
          line-height: 16px;
          width: 100%;
          font-family: $saira-400;
        }
        &_timer {
          display: flex;
          justify-content: flex-end;
          margin: auto 0 0;
          position: absolute;
          bottom: 16px;
          right: 0;

          .countdown_container {
            border: 1px solid rgba(64, 64, 64, 0.6);
            border-radius: 4px;
            width: 100px;
            padding: 3px;
            display: flex;
            justify-content: center;
            font-size: 12px;
          }
        }

        &_info {
          display: flex;
          justify-content: space-between;
          .info_item {
            display: flex;
            align-items: center;
            line-height: 1;
            font-size: 12px;
            .color_text {
              color: #fbb383;
              font-family: $saira-700;
              font-size: 14px;
              padding-left: 4px;
            }
          }
        }
      }

      &.free {
        .boost_item_logo {

        }
      }
    }
  }
}